@import './helpers/variables.less';

.landing-container {
    h1 {
        .robotoMono();
        font-size: 24px;
        text-align: left;
        line-height: 36px;
        width: auto;
        margin: 0;
        letter-spacing: 0;
        margin-bottom: 36px;
        color: @textColor;

        .media-md({
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 48px;
        });

        .media-lg({
            font-size: 38px;
            line-height: 48px;
        });
    }

    h2 {
        .robotoMono();
        font-size: 20px;
        margin-bottom: 12px;
        color: @textColor;

        .media-md({
            font-size: 24px;
        });
        .media-lg({
            font-size: 32px;
        });
    }

    h3 {
        .robotoMedium();
        font-size: 18px;
        margin-bottom: 12px;
        color: @textColor;

        .media-md({
            font-size: 22px;
        });
    }

    h4 {
        .robotoMedium();
        font-size: 16px;
        margin-bottom: 12px;
        color: @textColor;

        .media-md({
            font-size: 18px;
        });
    }

    p {
        .robotoRegular();
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 15px;
        color: @textColor;

        .media-md({
            font-size: 16px;
        });

        &.medium, .medium {
            .robotoMedium();
        }

        &.bold, .bold {
            .robotoBold();
        }

        &.email, .email {
            .robotoMedium();
        }

        &.link, .link {
            .robotoMedium();
            color: @textColor;
            text-decoration: underline;
        }
    }

    a {
        .robotoMedium();
        color: @textColor;
        text-decoration: none;
    }

    ul {
        .robotoRegular();
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
        color: @textColor;

        .media-md({
            font-size: 16px;
        });

        list-style: disc;
        padding-left: 20px;

        li {
            padding: 3px 0;
        }
    }

    ol {
        .robotoRegular();
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
        color: @textColor;

        .media-md({
            font-size: 16px;
        });

        padding-left: 20px;

        li {
            padding: 3px 0;
        }
    }
}