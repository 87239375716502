@import 'helpers/variables.less';
* {
    font-family: @fontRobotoRegular;
}
h1 {
    font-family: @fontRobotoMonoBold;
    font-size: 40pt;
    line-height: 79px;
}
h1.scroll {
    font-family: @fontRobotoBold;
    font-size: 20pt;
    line-height: 26px;
}
h2 {
    .robotoMedium();
    font-size: 14pt;
    line-height: 1.4em;
    .media-md({font-size: 16pt;});
}
h3 {
    .robotoRegular();
    font-size: 12pt;
    line-height: 1.4em;
    .media-md({font-size: 14pt;});
}
.copy-text {
    font-family: @fontRobotoLight;
    font-size: 14pt;
    line-height: 19px;
}
.account-text {
    font-family: @fontRobotoMedium;
    font-size: 16pt;
    line-height: 19px;
}
.footer-text {
    font-family: @fontRobotoRegular;
    font-size: 14pt;
    line-height: 24px;
}
.subline-text {
    font-family: @fontRobotoMedium;
    font-size: 16pt;
    line-height: 21px;
}
.overhead-text,
.buttonlarge-text {
    font-family: @fontRobotoMedium;
    font-size: 14pt;
    line-height: 19px;
}
.progress-text,
.floating-label-text,
.error-text {
    font-family: @fontRobotoRegular;
    font-size: 12pt;
    line-height: 19px;
}
.buttonsmall-text,
.metanavi-text {
    font-family: @fontRobotoMedium;
    font-size: 12pt;
    line-height: 19px;
}
mat-form-field.mat-form-field-appearance-legacy,
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) {
    .mat-form-field-underline,
    .mat-form-field-ripple {
        height: 3px;
    }
}
.mat-flat-button[mat-flat-button],
.mat-stroked-button[mat-stroked-button] {
    border-radius: 20px;
    line-height: 26px;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: @highlightColor;
    color: @whiteColor;
}
.mat-badge-medium .mat-badge-content {
    font-size: 11px;
    width: 20px;
    height: 20px;
}
.finmatch-bullet {
    border-radius: 30px;
    width: 12px;
    height: 4px;
    top: 9px;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    overflow: hidden;
    mat-icon.mat-icon.material-icons {
        position: absolute;
        top: 0;
        line-height: 4px !important;
        font-size: 42px !important;
        color: @highlightColor;
        left: -9px;
    }
}
textarea.mat-autosize { 
    overflow: hidden !important; 
}
