@import 'helpers/variables.less';

@cell-spacing: 20px;

.finmatch-form {
    .form-required-information {
        margin: -10px 0 0;
        padding-bottom: 10px;
        font-size: 12px;
    }

    mat-form-field,
    .field-label,
    app-form-field-readonly {
        width: 100%;
    }

    .section-label {
        margin: 30px 0 10px;
        font-size: 18px;
        font-weight: normal;
        .robotoLight();
    }

    .marked-field-labels {
        .field-label {
            font-weight: 600;
        }
    }

    .bulleted {
        .field-label {
            top: 0;
            margin-bottom: 0;
        }

        li {
            font-weight: bold;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        li > * {
            font-weight: normal;
        }
    }


    .field-label {
        position: relative;
        top: 4px;
    }

    mat-radio-group {
        mat-radio-button {
            padding: 14px 10px 20px;
        }

        &.ng-invalid {
            mat-radio-button {
                .mat-radio-label {
                    color: @errorColor;
                }
            }
        }
    }

    mat-checkbox {
        label {
            padding: 10px 0;
        }
    }

    ::ng-deep {
        label {
            padding: 10px 0;
        }
    }

    app-form-field-readonly {
        // placeholder
    }

    @cell-spacing-double: 2 * @cell-spacing;

    .form-row {
        width: calc(~'100% + @{cell-spacing-double}');
        margin-left: -(@cell-spacing);
        display: flex;
        flex-wrap: wrap;
    }

    .form-field {
        box-sizing: border-box;
        padding: 0 @cell-spacing;
        display: inline-block;
        vertical-align: top;

        &:first-child {
            mat-radio-button:first-child {
                padding-left: 0;
            }
        }
    }

    .form-field-25 {
        .form-field();
        width: 25%;
    }

    .form-field-33 {
        .form-field();
        width: 33.3%;
    }

    .form-field-50 {
        .form-field();
        width: 50%;
    }

    .form-field-75 {
        .form-field();
        width: 75%;
    }

    .form-field-100 {
        .form-field();
        width: 100%;
    }

    .mat-checkbox.toggle-checkbox {
        height: 3em;
        display: flex;
        align-items: center;
        padding-left: 8px;
    }

    .checkbox-list {
        display: flex;
        flex-direction: column;
        &.horizontal {
            flex-direction: row;
        }

        margin-bottom: 10px;

        .mat-checkbox:not(:last-child) {
            margin-right: 15px;
        }
    }
}
