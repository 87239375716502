@import 'variables';
@import 'media';

.full-height-content {
    min-height: calc(100vh - @headerHeightMobile - @footerHeightXs);

    .media-md({
        min-height: calc(100vh - @headerHeightMobile - @footerHeightSm);
        padding-bottom: 0;
    });

    .media-lg({
        min-height: calc(100vh - @headerHeight - @footerHeightLg);
    });
}

.content-layout {
    width: @appContentWidth;
    min-height: calc(100vh - @headerHeight);
    display: flex;
    padding: 5px 30px;
    box-sizing: border-box;
    max-width: @appContentWidth;
    justify-content: flex-start;
    margin: 0 auto;
    background: #f6f6f6;

    ::ng-deep .mat-drawer-container, ::ng-deep .mat-drawer {
        background-color: unset;
    }

    ::ng-deep .mat-drawer {
        border-right: unset;
        padding: 10px;
        overflow-x: hidden;

        .mat-drawer-inner-container {
            padding: 0 10px;
        }
    }

    .sidebar {
        max-width: 365px;
        min-width: 365px;
        height: 100%;
        overflow: hidden;

        ::ng-deep .sidebar-title {
            //font-family: @fontRobotoMono;
            font-size: 24px;
            font-weight: bold;
            color: @defaultBlue;
            text-transform: capitalize;
            line-height: 24px;
            padding: 1rem 0;
        }

        ::ng-deep .sidebar-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 1.5rem 0.625rem 0;

            .sidebar-list-item {
                border-radius: 0.25rem;
                //box-shadow: 0 2px 6px rgb(0 0 0 / 10%), 0 -2px 6px rgb(0 0 0 / 10%);
                background-color: #fff;
                padding: 1.25rem 1rem;
                box-sizing: border-box;
                border: 1px solid #ccc;
                padding-left: 1.7rem;
                //border-left: 0.5rem solid transparent; // Needed for '.selected'
                transition: background-color 0.1s;

                &.selected {
                    padding-left: 1.25rem;
                    border-left: 0.5rem solid @highlightColor;
                }

                &.selectable:hover {
                    background-color: darken(#fff, 2%);
                    cursor: pointer;
                }

                .sidebar-list-item-header {
                    display: flex;
                    line-height: 24px;
                    align-items: center;
                    justify-content: space-between;
                }

                .item-label {
                    font-weight: bold;
                    color: @defaultBlue;
                }
            }
        }
    }

    .content {
        display: flex;
        flex: 1;
        padding: 15px 15px 15px 15px;
        box-sizing: border-box;
        flex-flow: column;

        .content-header {
            border-bottom: 1px #d9d9d9 solid;
            padding: 6px 0 10px;
            margin-bottom: 30px;
            font-size: 14px;
            height: 60px;
            display: flex;
            gap: 1rem;
        }

        .content-body {
            flex: 1 1 auto;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 5px 5px 5px;
            height: 100px; // This is needed so the scrolling in dynamic flex works

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: @semiLightGray;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: @semiGrey2Color;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: @darkGreyColor;
            }
        }
    }
}

.content-body {
    .top-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.5rem;
        margin-bottom: 1rem;
    }

    .form-buttons {
        text-align: right;
        margin-top: 1rem;

        button {
            &:not(:first-child) {
                margin-left: 1.5rem;
            }
        }
    }
}
