@import "helpers/variables";

.card.login-form, .card.register-form, .card.password-reset-container, .card.password-reset-form {
    .mat-form-field-underline {
        display: none;
    }
}

.master-user-name.deactivated {
    color: @veryDarkGreyColor !important;
}
