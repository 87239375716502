.detail-container {
    .master-details {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 30px;
        margin-bottom: 30px;
        font-size: 14px;
        height: 47px;

        .selected-company {
            display: flex;
            padding: 5px 0;

            .master-user-name {
                font-weight: 600;
                margin-right: 20px;
            }
        }
    }

    .form-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > :not(:first-child) {
            margin-left: 1.5rem;
        }
    }
}
