.accordion-item-header {
    display: flex;
    padding: 10px 0;
    align-items: center;

    .accordion-item-toggle {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        mat-icon {
            font-size: 0.95rem;
            height: auto;
            width: auto;
        }
    }

    .accordion-item-toggle + .accordion-item-title {
        margin-left: 1rem;
    }


    .accordion-item-title {
        font-weight: 300;
        font-size: 1.05rem;
        user-select: none;
    }

    .accordion-item-actions {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;

        button {
            height: 30px;
            display: flex;
            align-items: center;
        }
    }
}

//.accordion-item-body {}
