@import '../helpers/variables.less';

.btn {
    margin: 10px 0 10px;

    .mat-icon.material-icons {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        display: inline-flex;
        align-items: center;
    }

    &.btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
            text-transform: uppercase;
            margin-left: 4px;
            display: inline-block;
        }
    }

    &.btn-sm {
        font-size: 0.75rem !important;
        padding: 0 1rem !important;
    }
}

.link-button {
    border: 2px solid #00d3b4;
    border-radius: 6px;
    padding: 12px 24px;
    background: #fff;
    font-family: @fontRoboto;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #000032;
    //transition: .3s ease;


    mat-icon {
        position: relative;
        top: 3px;
        font-size: 16px;
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.link-button:not(.active):hover {
    //border-color: #7DE6D7;
    //background-color: #7DE6D7;
    cursor: pointer;
    transition: .3s ease;
}

.link-button.active {
    border-color: #00d3b4;
    //transition: .3s ease;
}

//.link-button.link-button-bg:hover {
.link-button:hover {
    background: @highlightColorLight2;
    //border-color: #7DE6D7;
    //color: #fff;
}

.link-button.link-button-bg {
    background: #00d3b4;
    //border-color: #00d3b4;
    //transition: .3s ease;
}

.link-button.link-button-border {
    border-color: #00d3b4;
    transition: .3s ease;
}

//.link-button.link-button-border:hover {
//    background: #EBFAF8;
//}
