.table {
    width: 100%;

    .row-clickable {
        transition: background-color 0.1s ease;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.crefo-table {
    font-size: 14px;

    .mat-header-row {
        height: unset;
    }

    .mat-header-cell, .second-level, .first-level {
        font-weight: bold;
    }

    .mat-header-cell, .second-level {
        font-size: 1em;
        background-color: #e7e7e7;
    }

    .first-level {
        font-size: 1.025em;
        background-color: #b3b3b3;
    }

    .mat-header-cell {
        text-align: left;
        color: #000;
        border-top: none;
        border-left: none;
        border-bottom: none;
        background-color: #b3b3b3;
        box-sizing: border-box;
    }

    .mat-row {
        height: unset;
    }

    tr {
        td, th {
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 0.25rem !important; // Because of mat-table header cell

            &.hidden-col {
                border: none;
            }

            &.centered-col {
                text-align: center;
            }

            &.first-col {
                font-weight: bold;
                color: #4c555a;
            }
        }

        th {
            border-bottom: none;
        }
    }
}
