@import './media.less';

// Colors
@highlightColor: #00d3b4;
@highlightColorLight: #c5ffe4;
@highlightColorLight2: #eBfAf8;
@highlightColor-hover: #00bea2;
@textColor: #000032;
@errorColor: #ff465c;
@errorColor-hover: #d02e41;
@whiteColor: #ffffff;
@warnColor: #F0DC0A;

@veryDarkGreyColor: #727272;
@darkGreyColor: #979797;
@semiGrey2Color: #B7B7B7;
@semiGreyColor: #d9d9d9;
@semiLightGray: #e7e7e7;
@lightGray: #f6f6f6;

@defaultBlue: #666684;
@defaultBlue-hover: #33335b;
@white-hover: #fafafa;

@scrollbar-bg: unset;

// Fonts
@fontRoboto: 'Roboto', sans-serif;
@fontRobotoMonoBold: 'Roboto Mono Bold', monospace;
@fontRobotoMono: 'Roboto Mono', monospace;
@fontRobotoBold: 'Roboto Bold', sans-serif;
@fontRobotoMedium: 'Roboto Medium', sans-serif;
@fontRobotoRegular: 'Roboto Regular', sans-serif;
@fontRobotoLight: 'Roboto Light', sans-serif;

// Metrics
@standardPageWidth: 720px;
@inquiryWizardWidth: 720px;
@registrationWizardWidth: 720px;
@passwordChangeFormWidth: 720px;

@headerHeight: 120px;
@headerHeightMobile: 80px;

@footerHeightLg: 110px;
@footerHeightSm: 180px;
@footerHeightXs: 300px;

@appSidePadding: 24px;
@appContentWidth: 1300px;

.fullHeight {
    min-height: calc(100vh - @footerHeightXs);
    padding: @headerHeightMobile 12px 0;
    padding-top: @headerHeightMobile;

    .media-sm({
        min-height: calc(100vh - @footerHeightSm);
    });

    .media-lg({
        min-height: calc(100vh - @footerHeightLg);
        padding: @headerHeightMobile 24px 0;
        padding-top: @headerHeight;
    });
}

.robotoLight {
    font-family: @fontRoboto;
    font-weight: 300;
}
.robotoRegular {
    font-family: @fontRoboto;
    font-weight: 400;
}
.robotoMedium {
    font-family: @fontRoboto;
    font-weight: 500;
}
.robotoBold {
    font-family: @fontRoboto;
    font-weight: 700;
}
.robotoMono {
    font-family: @fontRobotoMono;
    font-weight: 500;
}

.clear-after {
    &:after {
        content: ' ';
        clear: both;
        display: block;
    }
}

// Inquiry wizard
@tile-separator-color: rgb(235, 235, 235);
@edit-icon-size: 16px;
