@import "styles/helpers/all";
@import "styles/helpers/variables.less";
@import "styles/landing-typography.less";
@import "styles/finmatch-form.less";
@import "styles/atoms/all.less";
@import "styles/molecules/all.less";
@import "styles/overwrite.less";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    background: white;
    color: @textColor;
    // padding: 0 12px;
    // .media-lg({
    //     padding: 0 24px;
    // })
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.recaptcha-protected {
    margin-bottom: -21px;
    font-size: 12px;
    margin-top: 10px;
    // color: lightgray;
    color: transparent;

    a {
        text-decoration: none;
        // color: lightgray;
        color: transparent !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE only style
    app-bank-offer-dialog .rounded-box, app-bank-offer-fast-lane-dialog .rounded-box {
        box-shadow: none;
    }

    mat-dialog-container {
        box-shadow: none !important;
    }
}

.rounded-box {
    position: relative;
    margin: 0 auto;
    //box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    background: white;
    border-radius: 8px;
}

.landing-content-wrapper {
    max-width: 1300px;
    min-height: 100px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 12px;

    .media-md(
    { // padding: 0 24px;
        padding: 0 12px;
    }
    );

    .media-lg({
        padding: 0;
    });
}

.landing-image-container {
    height: 100%;
    width: 100%;
    .media-lg({
        min-height: calc(100vh - @headerHeight)
    });

    .landing-image {
        // width: 85vw;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 auto;
        position: relative;
        .media-lg({
            min-height: calc(100vh - @headerHeight);
        });

        &.with-ribbon {
            padding-top: 170px;
            .media-lg({
                padding-top: 100px;
            });
        }

        .ribbon {
            position: absolute;
            background: @highlightColor;
            color: @whiteColor;
            transform: rotate(-30deg);
            text-align: center;
            top: 40px;
            left: -95px;
            width: 400px;
            box-shadow: 50px 17px 36px -11px rgba(130, 130, 130, 1);
            z-index: 2;

            .heading {
                .robotoMedium();
                font-size: 24px;
                line-height: 30px;
                padding-top: 6px;
            }

            .text {
                font-size: 15px;
                line-height: 30px;
            }
        }

        .image {
            background-position: right center;
            background-repeat: no-repeat;
            height: 300px;
            display: none;
            .media-lg(
            {
                background-size: cover;
                height: 100%;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
                z-index: 0;
                display: inline-block;
            }
            );
        }

        .image-mobile {
            background-position: right top;
            background-repeat: no-repeat;
            height: 300px;
            .media-lg({
                display: none;
            });
        }

        .landing-image-overlay {
            box-sizing: border-box;
            padding: 50px 12px;
            .media-lg(
            {
                position: relative;
                max-width: 1300px;
                height: 100%;
                padding: 50px 24px;
                z-index: 1;
                margin: 0 auto;
            }
            );

            .landing-page-image-text {
                color: rgb(0, 0, 50);
                font-size: 40px;
                width: 100%;

                .mint-line {
                    background: @highlightColor;
                    width: 0;
                    display: inline-block;
                    height: 4px;
                    margin: 0px 10px 11px 10px;
                    border-radius: 25px;
                    .media-xl({
                        width: 260px;
                    });
                }

                &.big {
                    box-sizing: border-box;
                    padding: 24px 0 0;
                    font-size: 24px;
                    .robotoMono();
                    .media-sm({
                        font-size: 30px;
                    });
                    .media-md({
                        font-size: 40px;
                        padding: 24px 0;
                    });
                    .media-xl({
                        padding: 0;
                    });
                }

                &.small {
                    box-sizing: border-box;
                    padding: 0 0 24px;
                    line-height: 24px;
                    .media-sm({
                        font-size: 18px;
                    });
                    .media-lg({
                        width: 500px;
                    });
                    .media-xl({
                        padding: 0;
                    });
                }

                .landing-prominent {
                    margin-top: 35px;
                    padding: 20px;
                    border: 2px solid @highlightColor;
                    margin-left: 0;
                    .media-lg({
                        margin-left: -20px;
                    });
                }
            }
        }
    }
}

.full-page-container {
    .media-lg({
        min-height: calc(70vh - @headerHeight);
        display: flex;
        align-items: center;
    });
}

.landing-container {
    box-sizing: border-box;
    width: 100%;
    background: @whiteColor;
}

.flex-column {
    flex-direction: column;
}

.media-box {
    width: 100%;
    padding: 60px 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;

    .media-xl({
        flex-direction: row;
        padding: 60px 0;
    });

    .video {
        width: 100%;
        order: 2;
        text-align: right;
        padding: 0;

        .media-xl({
            width: 65%;
            order: initial;
        });

        video {
            width: 100%;
        }

        .media-md({
            text-align: center;
        });
    }

    .text {
        width: 100%;
        .media-xl({
            width: 35%;
        });
    }

    &.full-width {
        flex-direction: column;

        .text,
        .video {
            width: 100%;
        }

        & > div {
            &:last-child {
                padding: 0;
            }
        }

        .video {
            position: relative;

            .video-title {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 12px;
                .robotoMono();
                background-color: #000;
                padding: 9px;
                color: @whiteColor;
                opacity: 0.5;
                .media-sm({
                    font-size: 18px;
                    padding: 12px
                });
                .media-md({
                    font-size: 30px;
                    padding: 24px;
                });
            }
        }
    }

    .big-text {
        font-size: 24px;
        line-height: 45px;
        .robotoMono();
        .media-sm({
            font-size: 24px;
        });
        .media-md({
            font-size: 29px;
        });
    }

    .small-text {
        font-size: 15px;
        line-height: 24px;
        .media-sm({
            font-size: 18px;
        });

        p {
            margin: 12px 0;
        }
    }

    & > div {
        &:last-child {
            padding-top: 24px;
            .media-sm({
                padding: 24px 0 24px 0;
            });
            .media-xl({
                padding-left: 30px;
            });
        }
    }
}

.svg-drawing-right {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 30%;
    margin: 20px 0;
    .media-lg({
        background-size: contain;
        position: absolute;
        top: 100px;
        right: -24px;
        width: 45%;
    });
}

// Standard FinMatch page header
h1 {
    font-family: @fontRobotoMonoBold;
    font-size: 22pt;
    line-height: 64px;
    text-align: center;
    width: calc(~"100% + 200px");
    margin-left: -100px;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

h1.mint-heading {
    height: 100%;
    padding: 100px 0;
    color: @highlightColor;
    white-space: normal;
    .media-md({
        padding: 150px 0;
    });
    .media-lg({
        white-space: nowrap;
    });
}

ngu-carousel {
    ngu-tile {
        .tile {
            box-shadow: none !important;
        }
    }

    .ngucarousel {
        overflow: initial !important;
    }

    .ngu-touch-container {
        overflow: hidden;
    }
}

.info-document {
    padding: 24px;
    .media-lg({
        padding: 50px 35% 50px 50px;
        .svg-drawing-right {
            width: 35%;
        }
    });
    background-color: @lightGray;
}

.forms-drawing {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 35%;
    margin: 20px 0;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.mat-menu-content {
    .mat-menu-item.cdk-program-focused {
        &:not(.mobile-nav-active) {
            background-color: inherit;
        }

        &.mobile-nav-active {
            background: @highlightColor;
            color: @whiteColor;

            &:hover {
                background: @highlightColor-hover;
                color: @whiteColor;
            }
        }
    }
}

mat-calendar-header .mat-calendar-controls .mat-calendar-period-button {
    margin-left: 12px;
}

.mde-inquiry-assignee-popover-content {
    .popover-name {
        color: @highlightColor;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .popover-phone,
    .popover-email {
        span.value {
            display: flex;
            .robotoRegular();
            color: @textColor;
            font-size: 16px;
            margin-top: 10px;

            .text-icon {
                color: @highlightColor;
                font-size: 18px;
                height: 18px;
            }

            a {
                text-decoration: none;
                color: @textColor;
            }
        }
    }
}

.ch2-style-light {
    // Override cookiehub styling
    .ch2-allow-all-btn {
        background: @highlightColor !important;
    }

    .ch2-save-settings-btn {
        background: #333 !important;
    }

    .ch2-settings-actions {
        p:first-child {
            display: none;
        }
    }
    .ch2-dialog-actions button {
        // Fix for broken IE11 layout
        box-sizing: content-box;
    }
}

.clear-icon {
    color: #666684;
    display: flex;
    align-items: center;

    .material-icons {
        font-size: 16px;
    }
}

mat-accordion {
    .custom-hover:hover {
        background: @white-hover !important;
    }

    .accordion-arrow {
        position: relative;
        top: -2px;
        display: flex;
        align-items: center;
        width: 30px;

        .material-icons {
            font-size: 16px;
            color: @textColor;
            transition: 200ms;
            vertical-align: middle;
        }

        &.expanded {
            .material-icons {
                transform: rotate(180deg)
            }
        }
    }
}

.display-none {
    display: none !important;
}

.relative {
    position: relative;
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.status-green {
    color: @highlightColor !important;
}

.status-red {
    color: @errorColor !important;
}

.status-neutral {
    color: rgb(150, 150, 150);
}

// Workaround for https://github.com/angular/material2/issues/4580.
mat-form-field .mat-form-field {
    &-underline {
        position: static;
    }

    &-subscript-wrapper {
        position: static;
    }
}

app-bank-offer-dialog .offer, app-bank-offer-fast-lane-dialog .offer {
    width: 100%;

    .form-section {
        display: flex;
    }

    .inquiry-details {
        width: 260px;
    }

    .offer-details {
        flex: 1;
        padding-left: 20px;

        .section-label {
            margin-top: 0;
        }

        .form-row {
            margin-left: 0;
        }
    }
}

.mat-dialog-container {
    will-change: transform, opacity;
}

.mat-dialog-content {
    backface-visibility: hidden !important;
}

.mat-dialog-actions .action-button:not(:last-child) {
    margin-right: 20px;
}

.content-actions {
    display: flex;

    .download-all-action {
        margin-left: auto;
        margin-bottom: -8px;
    }
}

.introjs-helperLayer {
    background: transparent !important;
    background-color: transparent !important;
}
.introjs-helperNumberLayer {
    background: @highlightColor !important;
}
.introjs-skipbutton:not(.introjs-donebutton) {
    display: none !important;
}
.introjs-tooltipbuttons {
    display: flex;
}
.introjs-nextbutton {
    margin-left: auto;
}
.introjs-tooltiptext {
    padding: 0 3px;
    h2 {
        font-size: 18px;
        font-weight: 500;
        color: @highlightColor;
    }
    p {
        padding: 10px 0;
        line-height: 1.1;
    }
}

.introjs-overlay {
    opacity: 0 !important;
}
.introjs-prevbutton {
    margin-right: 5px !important;
}

.introjs-helperLayer:before {
    //opacity: 0;
    content: '';
    position: fixed;
    width: inherit;
    height: inherit;
    border-radius: 0.5em;
    box-shadow: 0 0 0 1000em rgba(0,0,0, .7);
    opacity: 1;
}

.introjs-helperLayer:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000;
}
.introjs-tooltip {
    background-color: @whiteColor;
    color: @textColor;
    font-family: @fontRobotoLight !important;
    font-size: 14px;

    arrow {
        display: inline-block;
        transform: rotate(90deg);
        font-size: 14px;
    }
}

.introjs-button, .introjs-button:focus, .introjs-button:active,
.introjs-disabled, .introjs-disabled:focus  {
    color: #000032 !important;
    background: #00d3b4 !important;
    border: 2px solid #00d3b4 !important;
    padding: 2px 16px !important;
    border-radius: 6px !important;
    text-decoration: none !important;
    font-family: @fontRoboto !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    outline: none !important;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2) !important;
    text-shadow: none !important;
    text-transform: uppercase;

    &:hover {
        padding: 2px 16px !important;
        background: #00d3b4 !important;
        color: #fff !important;
    }
}

.introjs-button:hover, .introjs-button:focus, .introjs-button:active {
    border: 1px solid #fff
}
.introjs-disabled, .introjs-disabled:focus, .introjs-disabled:hover {
    color: #ccc;
    border: 1px solid transparent;
}

.introjs-arrow {
    border: 10px solid #fff;
}
.introjs-arrow.top, .introjs-arrow.top-middle, .introjs-arrow.top-right {
    border-color: transparent transparent rgba(000, 0, 0, 0.5);
    top: -20px;
    left: 20px;
}
.introjs-arrow.bottom, .introjs-arrow.bottom-middle, .introjs-arrow.bottom-right {
    border-color: rgba(000, 0, 0, 0.5) transparent transparent;
    bottom: -20px;
    left: 20px;
}
.introjs-arrow.left, .introjs-arrow.right {
    top: 20px;
}
.introjs-arrow.left-bottom, .introjs-arrow.right-bottom {
    bottom: 20px;
}

.introjs-arrow.left, .introjs-arrow.left-bottom {
    left: -20px;
    border-color: transparent rgba(000, 0, 0, 0.5) transparent transparent;
}
.introjs-arrow.right, .introjs-arrow.right-bottom {
    right: -20px;
    border-color: transparent transparent transparent rgba(000, 0, 0, 0.5);
}

.tutorial-open {
    overflow: hidden;
}

.warning-color {
    color: @warnColor;
}

/**
Ngx dropzone custom changes:
 */
.finmatch-dropzone {
    border: 2px dashed @highlightColor !important;
    border-radius: 20px !important;
    display: flex;
    justify-content: center;

    .robotoRegular();

    h2 {
        margin: 0 !important;
    }

    .dz-success-mark, .dz-error-mark {
        display: none;
    }
    .dz-preview {
        margin: auto;
        cursor: default;
    }
    .dz-remove {
        text-decoration: none;
        color: @darkGreyColor;
        text-transform: uppercase;
        .robotoMedium();
        cursor: pointer;
    }
}

.module-error-indicator {
    display: inline-block;
    position: relative;
    margin-top: 4px;
    text-align: left;

    color: @errorColor;
    animation: ErrorAppear 150ms;

    .material-icons {
        position: absolute;
        top: 1px;
        left: 0;
        font-size: 16px;
        vertical-align: middle;
    }

    .error-text {
        width: 340px;
        display: inline-block;
        padding-left: 24px;
        .robotoMedium();
        font-size: 12px;
    }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
}

.finmatch-scroll {
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        background: @semiLightGray;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: @semiGrey2Color;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: @darkGreyColor;
    }

    scrollbar-width: thin;
}

[hidden] {
    visibility: hidden;
}

.risk-green {
    background: lightgreen;
}
.risk-yellow {
    background: lightyellow;
}
.risk-orange {
    background: #ffaf11;
}
.risk-red {
    background: #ff4040;
}

.overlay-message {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
    color: @textColor;
}

.blur {
    filter: blur(4px);
}

.authentication-error {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    color: @errorColor;
    line-height: 1.2;
}

.master-list-scroll-container .master-list-element, mat-list-item {
    border: 1px solid #ccc;
}

//.master-list-element.ng-star-inserted {
//    border: none;
//}

