.media-sm(@rules) {
    @media (min-width: 576px) {
        @rules();
    }
}
.media-md(@rules) {
    @media (min-width: 768px) {
        @rules();
    }
}
.media-lg(@rules) {
    @media (min-width: 992px) {
        @rules();
    }
}
.media-xl(@rules) {
    @media (min-width: 1300px) {
        @rules();
    }
}
