@import 'helpers/variables.less';

// Hide spin buttons on number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Form field underline
mat-form-field.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 1px !important;

    // Ripple
    .mat-form-field-ripple {
        height: 1px !important;
    }
}

// Disabled input suffix color
mat-form-field.mat-form-field-disabled {
    .mat-form-field-suffix {
        color: rgba(0, 0, 0, 0.26);
    }
}

// Disabled input label color
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.26);
}

// Input error message
mat-form-field.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 3px;
}

// Expansion panel contents
.mat-expansion-panel {
    color: @textColor;
}

// Buttons
.mat-button:not(.mat-button-disabled) {
    //color: @defaultBlue;
    border: 2px solid @highlightColor !important;
    border-radius: 6px !important;
    padding: 2px 20px 0 20px !important;

    &:hover {
        color: @textColor !important;
        background: @highlightColorLight2 !important;
    }
}

// Number inputs
input[type="number"] {
    text-align: right;
}

// Padding in inputs with suffixes
.mat-form-field .mat-form-field-suffix {
    padding-left: 6px;
}

// Radio button
@radio-button-size: 18px;
mat-radio-button {
    .mat-radio-container {
        width: @radio-button-size;
        height: @radio-button-size;
        .mat-radio-outer-circle {
            width: @radio-button-size;
            height: @radio-button-size;
        }
        .mat-radio-inner-circle {
            width: @radio-button-size;
            height: @radio-button-size;
        }
    }
}

.mat-button-toggle-checked {
    background-color: @highlightColor;
    color: @whiteColor
}

// Checkbox
mat-checkbox {
    .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
    }
}

mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(1);
    width: 8px;
    height: 8px;
    top: 5px;
    left: 5px;
}

.mat-flat-button[mat-flat-button],
.mat-button[mat-button],
.mat-raised-button[mat-raised-button]
{
    text-transform: uppercase;
    border-radius: 6px;
    padding: 2px 24px 0 24px;
    line-height: 28px;
    //transition: .3s ease;

    .robotoMedium();
    &.cdk-program-focused
    {
        .mat-button-focus-overlay
        {
            opacity: 0;
        }
    }

    &:not([class*="mat-elevation-z"]) {
        box-shadow: none;
    }
}

.mat-icon-button[mat-icon-button] {
    color: @darkGreyColor;

    .mat-button-ripple {
        display: none;
    }
    &:not([disabled]):hover {
        .material-icons {
            color: @highlightColor;
        }
    }
    &.cdk-program-focused
    {
        .mat-button-focus-overlay
        {
            opacity: 0;
        }
    }
}

.mat-button[mat-button] {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
    min-width: 0;

    &:not([disabled]):hover {
        color: @highlightColor;
        background-color: transparent;
    }

    .mat-button-focus-overlay {
        display: none;
    }

    .mat-button-ripple {
        display: none;
    }
}

.mat-flat-button[mat-flat-button]:not([disabled]) {
    color: @textColor;
    border: 2px solid #00d3b4;
    background: @highlightColor;
    //transition: .3s ease;

    &[color="secondary"] {
        background: transparent;
        //background: @defaultBlue;
        color: @textColor;
        &:hover {
            background: @highlightColorLight2;
            //background: @defaultBlue-hover;
        }
    }
    &[color="primary"] {
        color: @textColor;
        background: @highlightColor;
        &:hover {
            color: #fff;
            //background: @highlightColor-hover;
        }
    }
    &[color="warn"] {
        color: white;
        background: @errorColor;
        &:hover {
            background: @errorColor-hover;
        }
    }
}

mat-tooltip-component {
    .mat-tooltip {
        font-size: 12px;
    }
}


// multiselect
.mat-select-panel {
    .mat-select-content {
        mat-option {
            .mat-pseudo-checkbox {
                transform: scale(0.8);
            }
        }
    }
}

mat-option.sub-value {
    padding-left: 30px;
}
