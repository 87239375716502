.financial-statement-container {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.05rem;
        border-bottom: 4px double rgba(0,0,0,0.2);
        font-weight: bold;
        padding-bottom: 0.5rem;
    }

    .expandable {
        > .financial-statement-list-item-content {
            font-weight: bold;
        }

        &.open {
            .financial-statement-position-list {
                display: block;
            }
        }
    }

    .balance-sheet-top {
        border-bottom: none !important;
    }

    .balance-sheet-top + .financial-statement-position-list, .list-top {
        padding-left: 40px !important;
    }

    .financial-statement-position-list {
        display: none;
        padding-left: 20px;
    }

    .financial-statement-list-item {

        .financial-statement-list-item-content {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            padding: 0.25rem 0;

            .position-name {
                max-width: 600px;
            }

            .position-value {
                flex: 1;
                text-align: end;
            }
        }
    }
}

.pandl-container {
    .title {
        margin-top: 0.5rem;
    }

    .financial-statement-position-list {
        //margin-left: 1rem;
    }

    .financial-statement-position-list:first-child {
        //margin-left: 40px;
    }
}

.pandl-container, .financial-statement-container {
    .font-bold {
        font-weight: bold;
    }

    .font-italic {
        font-style: italic;
    }
}
