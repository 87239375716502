.container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 1150px;
    max-width: 1300px;
    margin: 0 auto;
    justify-content: space-between;
}
