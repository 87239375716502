small {
    font-size: 0.75em !important;
}

.text-muted {
    color: #939da3 !important;
}

.form-sub-header {
    font-size: 1.125rem;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-sm {
    font-size: 0.825rem;
}

.info-icon {
    position: absolute;
    margin-left: 6px;
    font-size: 21px;
    color: @veryDarkGreyColor;
    cursor: pointer;
}
